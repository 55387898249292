@import "../../style/variables.scss";

.login-bg {
  background: url("/assets/images/login_bg.png");
  width: 50%;
  position: absolute;
  height: 100%;
  left: 0px;
  object-fit: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

a.hyperlink {
  font-size: 14px;
  color: $blue;
}

.right-half {
  background-color: #fffffd;
  position: absolute;
  right: 0px;
  width: 50%;
  height: 100%;
  text-align: center;
  .container {
    position: absolute;
    left: 50%;
    height: 100%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0 85px;
    text-align: left;
    div.form {
      .login {
        div[class*="col-"] {
          padding: 0;
        }
      }
    }
    .logo-sigpae {
      text-align: center;
      @media screen and (max-height: 610px) {
        padding-top: 2rem;
        img {
          height: auto;
          width: 300px;
        }
      }
      @media screen and (min-height: 611px) {
        padding-top: 2rem;
        padding-bottom: 1rem;
        img {
          height: 105px;
          width: 427px;
        }
      }
    }
    .terms {
      font-size: 12px;
      color: $blue;
    }
    span.text-terms {
      padding-left: 2rem;
    }
    .logo-prefeitura {
      text-align: center;
      position: absolute;
      left: 37%;
      bottom: 2em;
      @media screen and (max-height: 610px) {
        padding-top: 1rem;
        img {
          height: auto;
          width: 100px;
        }
      }
      @media screen and (min-height: 611px) {
        padding-top: 2rem;
        img {
          height: 65px;
          width: 171px;
        }
      }
    }
  }
}

div.text-right.back {
  color: $blue;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

div.input-group.email-sme {
  .col-8,
  .col-4 {
    padding-left: 0;
  }
  .col-6 {
    padding-right: 0;
    margin-right: -12px;
  }
  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-append {
    padding-top: 35px;
    .input-group-text {
      height: 38px;
      font-size: 14px;
    }
  }
}

.texto-simples {
  color: #42474a;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  padding: 5;
  &-verde {
    @extend .texto-simples;
    align-items: center;
    text-align: center;
    color: #297805;
  }
  &-vermelho {
    @extend .texto-simples;
    align-items: center;
    text-align: center;
    color: #b40c02;
  }
  &-grande {
    @extend .texto-simples;
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }
}

.alinha-direita {
  display: flex;
  justify-content: flex-end;
}

.div-circular {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 7em;
  height: 7em;
  &-verde {
    @extend .div-circular;
    border: 3px solid #297805;
  }
  &-vermelho {
    @extend .div-circular;
    border: 3px solid #b40c02;
  }
}

.check {
  color: #42474a;
  &-verde {
    @extend .check;
    color: #297805;
  }
  &-vermelho {
    @extend .check;
    color: #b40c02;
  }
}

.alinha-centro {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: block;
    grid-auto-rows: auto;

    &:first-child {
      width: 50%;
    }
  }
}

.signup-form {
  .tabs {
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    .tab {
      padding-bottom: 5px;
      &.active {
        color: $green;
        border-bottom: 3px solid $green;
      }
      &.inactive {
        color: $grayDisabled;
        border-bottom: 3px solid $grayDisabled;
      }
    }
  }
}

.message-signup-success {
  font-size: 14px;
  color: $blackInput;
  text-align: justify;
}
