@import "../../assets/css/variaveis";
$mq-mobile: "(max-width: 540px)";

h1 {
  font-size: 40px;
  font-weight: bold;
  color: $corAzulPortal;
}

.banner-home {
  background: linear-gradient(90deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.9) 33.83%,
          rgba(255, 255, 255, 0.2) 64.77%,
          rgba(255, 255, 255, 0.324) 92.11%),
  url("../../assets/img/banner-home.png");

  color: $corPretoPortal;
  height: 550px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center right;

  @media #{$mq-mobile} {
    background: url("../../assets/img/banner-home-mobile.png");
    height: 850px;
    background-size: cover;
    background-position: bottom center;
  }


  .conteudo {
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 16px;
    width: 80%;
    color: black;

    @media #{$mq-mobile} {
      position: static;
      transform: none;
      padding-top: 5vh;
    }

    a{
      word-break: break-all;
    }

    .titulo {
      font-size: 40px;
      line-height: 47px;
      text-align: center;
      font-weight: bold;
      color: black;

      @media #{$mq-mobile} {
        font-size: 32px;
        line-height: 38px;
      }

    }

    .subtitulo {
      font-size: 32px;
      line-height: 35px;
      font-style: normal;
      font-weight: 500;
      text-align: center;

      @media #{$mq-mobile} {
        font-size: 24px;
        line-height: normal;
      }
    }

    .subtitulo-menor{
      font-style: normal;
      font-weight: bold;
      text-align: center;

      @media #{$mq-mobile} {
        font-size: 12px;
        line-height: normal;
      }
    }

    .label-consulta{
      text-align: left;
    }

    .italic{
      font-style: italic;
    }
  }
}

.lista-home {
  list-style-position: inside;

  li {
    list-style-type: none;
  }

  li:before {
    content: "•";
    color: $corAzulPortal;
    font-size: 50px;
    margin-right: 10px;
    vertical-align: middle;
    line-height: 0.8em;
  }

}

.container-resultado{
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  font-weight: bold;
  line-height: 18px;
  background: #ECECEC;
  border-bottom: 8px solid;

  .titulo-resultado{
    font-size: 18px;
    line-height: 21px;
  }

  .subtitulo-resultado{
    font-size: 16px;
    line-height: 19px;
  }

  .texto{
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  .texto-menor{
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 1em;
  }

  .fas{
    line-height: 24px;
  }

  ul{
    padding-left: 16px;
    margin: 0;
  }
}

.cont-amarelo{
  border-color: #C0640E;

  .fas{
    color: #C0640E;
  }
}

.cont-verde{
  border-color: #297805;

  .fas{
    color: #297805;
  }
}

.cont-vermelho{
  border-color: #B40C02;

  .fas{
    color: #B40C02;
  }
}

.span_erro{
  color: red;
  font-size: 0.9rem;
  display: inline-block;
}

.texto-cadastro-completo{
  font-style: italic;
  font-weight: bold;
  text-align: center;
  margin: 16px 0;
}

.form-consulta{
  margin: auto;
  width: 75%;

  .form-row{
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: center;
  }

  @media #{$mq-mobile} {
    width: 90%;
    margin: auto;

    .botao-form{
      width: 60%;
      margin-top: 8px;
      text-align: center;
    }
  }
}
