#rodape {
  @media only screen and (min-width: 992px) {
    height: 320px;
  }
  .footer {
    background: #363636; 
    color: #fff;
  }
  .area-rodape {
    @media only screen and (min-width: 992px) {
      height: 275px;
    }
    .logo-prefeitura {
      margin-top: 65px;
      img {
        height: 147.6px;
        width: 160px;
      }
    }
    .governo-ul {
      font-size: 12px;
      padding-left: 20px;
    }
    .bd-contact {
      border-left: 1px solid #FFFFFF;
      box-sizing: border-box;
    }
    .footer-title {
      color: #fff;
      font-size: 17px;
      font-weight: bold;
    }
    a {
      color: #fff;
    }
    .sa {
      position: relative;
      display: inline-block;
    }
    .seloa {
      img {
        max-width: 135px !important;
      }
    }
  }
  ul.list-unstyled {
    li {
      a {
        color: #fff;
      }
    }
  }
  .form-control.fonte-doze.rounded-pill {
    font-size: 12px;
    color: white !important;
    border-color: white !important;
    border-radius: 10px !important;
    padding-top: 5px;
    line-height: 18px;
    height: 38px;
    option {
      color: black;
    }
  }
  .cc {
    font-size: 12px;
    @media only screen and (max-width: 992px) {
      padding-top: 20px;
    }
  }
  .endereco {
    padding: 15px 0 0 0;
    font-size: 12px;
    background: #424242;
    color: #fff;
  }
  .sa .st {
    visibility: hidden;
    width: 220px;
    background-color: #e2e2e2;
    color: #000;
    text-align: left;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;
  }
  .sat .st {
    bottom: 110%;
  }
}
